<div class="card overflow-auto flex flex-col md:flex-row sm:mr-6">
  <div class="bg-primary-600/10 p-6 flex-auto max-w-[300px]">
    <h2 class="headline mb-4 text-primary-600">Nexus</h2>

    <p class="caption">
      O Nexus é um sistema que integra todos os sistemas legados da PMDF em uma única plataforma. 
      Desenvolvido com o objetivo de modernizar e otimizar os processos da instituição.
    </p>

    <p class="caption">
      O novo sistema traz 
      consigo uma abordagem inovadora, com aplicações móveis e interfaces intuitivas e fáceis de utilizar.
    </p>
  </div>

  <div class="p-6 flex-auto max-w-[400px]">
    <h3 class="body-2 m-0">Módulos</h3>

    <div class="mt-4 grid grid-cols-3">
      <a
        (click)="close()"
        *ngFor="let feature of features"
        [routerLink]="feature.route"
        class="text-dark p-3 text-center hover:bg-primary-600/10 hover:text-primary-600 transition duration-400 ease-out-swift rounded block no-underline">
        <mat-icon
          [svgIcon]="feature.icon"
          class="icon-xl"
          color="primary"></mat-icon>
        <div class="body-2 mt-2">{{ feature.label }}</div>
      </a>
    </div>
  </div>

  <div class="p-6 flex-auto max-w-[350px]">
    <h3 class="body-2 m-0">Páginas</h3>

    <div class="mt-6 grid grid-cols-2 gap-x-12 gap-y-4">
      <a
        (click)="close()"
        *ngFor="let page of pages"
        [routerLink]="page.route"
        class="text-dark body-1 no-underline hover:text-primary-600 transition duration-400 ease-out-swift"
      >{{ page.label }}</a
      >
    </div>
  </div>
</div>
