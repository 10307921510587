import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule],
  selector: 'vex-search-modal',
  template: `
    <div>
      <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
        <mat-icon
          svgIcon="mat:search"
          class="text-secondary flex-none"></mat-icon>
        <input
          type="text"
          placeholder="Pesquisar..."
          class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary" />
        <button
          class="flex-none ltr:-mr-2 rtl:-ml-2 text-secondary"
          type="button"
          mat-icon-button>
          <mat-icon svgIcon="mat:settings"></mat-icon>
        </button>
      </div>

      <div class="p-4">
        <div class="text-xs font-semibold text-secondary px-2 mb-2">
          Resultados:
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class SearchModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
